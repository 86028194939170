import React from 'react';
import { graphql } from 'gatsby';

import { BetaPageLanding } from 'components/Beta';

export default function PageBetaLanding(props) {
  return <BetaPageLanding {...props} />;
}

export const query = graphql`
  query PageLandingBetaQuery {
    page: strapiLandingBeta {
      seo {
        title
        description
        keywords
      }
      heading
      intro_text
      text
      text_2
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
          }
        }
      }
    }
  }
`;
